<!-- Finder particular de Agencias Afectas -->

<template>
  <div class="agenF" v-if="loaded">
    <div style="display:flex;justify-content:center">
      <v-sheet v-if="!viewMtoOnly" :elevation="4">
          
        <!-- Cabecera -->
        <div class="cabecera">     
          <baseHeader
            :cfg="$cfe.headers.finder"
            :titulo='$store.state[storeName].titulo'
            @onEvent="eventHeader"> 
          </baseHeader>
        </div>


        <!-- Contenido -->
        <div class="contenedor">

          <!-- Filtro de Busqueda -->         
          <div class="columna" style="width:650px">
            <div class="conflex" style="width:65%;">
              <v-select
                v-bind="$select"
                style="width:50%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-model="ct.afecta_zona[2]"
                :label="ct.afecta_zona[1]"
                :items="jefeszona"
                item-value="id"
                item-text="name"
                :disabled="zona >0">            
              </v-select>
              
              <v-btn
                v-bind="$cfe.btn.busca"
                style="margin-left:5px"
                @click="eventFiltro('buscar')">            
                  <v-icon dark>mdi-filter</v-icon>
              </v-btn>              
            </div>
            
            <uiText
              style="width:65%;"
              v-model="ct.name[2]"
              :label="ct.name[1]"
              @Enter="eventFiltro('buscar')"> 
            </uiText>
          </div>
          
          <!-- Grid -->
          <baseGridMD
            style="margin-top:10px"
            :store="storeName"
            :headers="headerGrid"
            :items-per-page="10"           
            dense
            @onEvent="execAccion">

              <!-- Slot Botonera Top Grid -->
              <template v-slot:gridTop="{}">
                <div>

                  <baseBtraMto
                    :perm="permMto"
                    :modulo="btGridCfg"
                    @onEvent="execAccion">
                  </baseBtraMto>

                </div>
              </template>

              <!-- Slot Acciones Row -->
              <template v-slot:acciones="{ item }">                
                <baseBtraMto
                  :perm="permMto"
                  :modulo="btRowCfg"
                  @onEvent="execAccion({ ...$event, ...{item:item} })">
                </baseBtraMto>
              </template>
              
          </baseGridMD>       
        </div>
      </v-sheet>

      <!-- Mto  -->      
      <component      
        :is="componenteDinamico"  
        :syncDisparo="syncDisparoSend"
        :syncAccion="syncAccionSend"
        syncUpdate        
        :storeRaiz="storeName"
        :masterStore="storeName"
        :ctrlCt="ctrlCt"
        :readOnly="readOnly"
        :viewMtoOnly="viewMtoOnly"
        @onEvent="$emit('onEvent', $event)">
      </component>
    </div>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import baseBtraMto from "@/base/baseBtraMto";
  import agenM from "@/components/agenM";
  
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, baseBtraMto, agenM },
    props: {},

    data() {
      return {
        stIni: {
          api: "agenF",
          titulo:"Agencias Afectas", 
          name: "agenF",
          mView: 'agenM',
          pView:[]
        },

        jefeszona:[]
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        // Cabecera Finder
        this.headers= [{
            // añadir en name los nombres de las cabeceras que coincidiran con las busquedas
            // si solo hubiera un elemento la cabecera tomará este por defecto 
            // Si no encontrara el nombre pondría el elemento 0
            name:[],
            fields:[
              { text: "Acciones", value: "acciones", sortable: false, width:'10%', slot:true },
              { text: "Nombre", value: "afecta_name", width: "45%" },
              { text: "Empresa", value: "cont1", width: "45%" }              
            ]}          
        ];      

        this.headerGrid= this.headerSet(); 
        
        // asigno zona recibida
        this.$set(this.ct.afecta_zona, 2, this.zona);   


        // items adicionales
        // array de zonas 
        this.jefeszona= this.$store.state.datos_iniciales.zonas;
        this.jefeszona.unshift({ id: "0", name: "( Selecciona )" });
      },  

    }

  };
</script>
