<!-- Mto particular de Agencias Afectas -->

<template>
  <div class="agen_M" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"      
       persistent>
      
        <template v-slot:controles="{}">

          <div>
            
            <!-- Cabecera -->
            <div class="cabecera">     
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'                
                @onEvent="eventHeader">
              </baseHeader>
            </div>  
            
            <v-sheet :elevation="4">
              <div class="contenedor" style="width:750px">      
        
                <div class="conflex">
                  <!-- Botoneras -->

                  <!-- Mto --> 
                  <div>                 
                    <baseBtraMto
                      v-if="!readOnly"
                      :perm="permMto"
                      :modulo="btMtoCfg" 
                      :estado="estado"
                      @onEvent="execAccion">        
                    </baseBtraMto>
                  </div>      
                </div>
                
                <div class="conflex">
                  <div class="columna" style="width:70%;padding-top:10px">
                    <ctrlF  
                      style="width:100%"                  
                      :ct="ct.afecta_zona"
                      :edicion="!noEdit">                        
                    </ctrlF>
                    
                    <uiText
                      v-bind="$input"
                      v-model="ct.afecta_name[2]"
                      :label="ct.afecta_name[1]"
                      :disabled="noEdit">
                    </uiText>
                  
                    <div class="conflex">
                      <uiText
                        style="width:74%"
                        v-bind="$input"
                        v-model="ct.name[2]"
                        :label="ct.name[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText
                        style="width:25%;margin-left:5px"
                        v-bind="$input"
                        v-model="ct.cif[2]"
                        :label="ct.cif[1]"
                        :disabled="noEdit">
                      </uiText>                       
                    </div>

                    <uiText                    
                      v-bind="$input"
                      v-model="ct.cont1[2]"
                      :label="ct.cont1[1]"
                      :disabled="noEdit">
                    </uiText>
                    
                    <div class="conflex">
                      <uiText           
                        style="width:79%"         
                        v-bind="$input"
                        v-model="ct.dire[2]"
                        :label="ct.dire[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText           
                        style="width:20%;margin-left:5px"         
                        v-bind="$input"
                        v-model="ct.cp[2]"
                        :label="ct.cp[1]"
                        :disabled="noEdit">
                      </uiText>                   
                    </div>

                    <div class="conflex">
                      <uiText           
                        style="width:50%"         
                        v-bind="$input"
                        v-model="ct.pob[2]"
                        :label="ct.pob[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText           
                        style="width:49%;margin-left:5px"         
                        v-bind="$input"
                        v-model="ct.pro[2]"
                        :label="ct.pro[1]"
                        :disabled="noEdit">
                      </uiText>
                    </div>            

                    <div class="conflex">
                      <uiText           
                        style="width:33%"         
                        v-bind="$input"
                        v-model="ct.tlf1[2]"
                        :label="ct.tlf1[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText           
                        style="width:33%;margin-left:5px"         
                        v-bind="$input"
                        v-model="ct.tlf3[2]"
                        :label="ct.tlf3[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText           
                        style="width:32%;margin-left:5px"         
                        v-bind="$input"
                        v-model="ct.fax1[2]"
                        :label="ct.fax1[1]"
                        :disabled="noEdit">
                      </uiText>                            
                    </div>

                    <div class="conflex">
                      <uiText           
                        style="width:33%"         
                        v-bind="$input"
                        v-model="ct.tlf2[2]"
                        :label="ct.tlf2[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText           
                        style="width:33%;margin-left:5px"         
                        v-bind="$input"
                        v-model="ct.tlf4[2]"
                        :label="ct.tlf4[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText           
                        style="width:32%;margin-left:5px"         
                        v-bind="$input"
                        v-model="ct.fax2[2]"
                        :label="ct.fax2[1]"
                        :disabled="noEdit">
                      </uiText>
                    </div>

                    <div class="conflex">
                      <uiText           
                        style="width:50%"         
                        v-bind="$input"
                        v-model="ct.email1[2]"
                        :label="ct.email1[1]"
                        :disabled="noEdit">
                      </uiText>

                      <uiText           
                        style="width:49%;margin-left:5px"         
                        v-bind="$input"
                        v-model="ct.email2[2]"
                        :label="ct.email2[1]"
                        :disabled="noEdit">
                      </uiText>
                    </div>

                    <!-- <v-textarea
                      v-bind="$textarea"
                      v-model="ct.obs[2]"
                      :label="ct.obs[1]"
                      no-resize
                      rows="4"
                      :disabled="noEdit">
                    </v-textarea> -->
                    <uiObs3                       
                      :record="record"
                      :schema="ct"
                      :schemaComponente="ct.obs"
                      :edicion="!noEdit">                       
                    </uiObs3>
                    
                    <uiText
                      v-bind="$input"
                      v-model="ct.cta[2]"
                      :label="ct.cta[1]"
                      :disabled="noEdit">
                    </uiText>  

                    <ctrlF                    
                      :ct="ct.afecta_f"
                      :edicion=false>                        
                    </ctrlF>                

                    <v-select
                      v-bind="$select"
                      v-model="ct.afecta_apd[2]"
                      :label="ct.afecta_apd[1]"
                      :items="$store.state.datos_iniciales.afecta_tipo"
                      :disabled="true"
                      item-value="id"
                      item-text="name">            
                    </v-select>
                  </div>

                  <div class="columna list_claves" style="padding-left:10px;width:30%">
                    <div class="cab">CLAVES PRODUCCIÓN</div>
                    <v-sheet v-bind="$cfg.styles.marco">
                      <div class="conflex">
                        <v-btn 
                          icon 
                          @click="addClaves">
                            <v-icon>{{'mdi-plus'}}</v-icon>
                        </v-btn>

                        <v-btn 
                          icon 
                          style="margin-left:10px"
                          @click="delClaves">
                            <v-icon>{{'mdi-minus'}}</v-icon>
                        </v-btn>
                      </div>

                      <v-list 
                        dense 
                        style="max-height: 244px"
                        class="overflow-y-auto">
                        <v-list-item-group v-model="selected">                      
                          <v-list-item
                            v-for="(item, index) in itemsClaves"
                            :key="index">                          
                          
                              <v-list-item-content>
                                <v-list-item-title v-text="item.clave"></v-list-item-title>                   
                              </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                        
                      </v-list>
                    </v-sheet>          
                  </div>
                </div>
              </div> 
            </v-sheet>

            <!-- Componentes dinámicos -->   
            <component      
              :is="componenteDinamico"
              syncUpdate
              :storeRaiz="storeName"
              :masterStore="storeName"              
              :readOnly="readOnly"              
              :accionClave="accionClave"            
              @update="updateClaves"
              @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
            </component>        
           
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";  
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";  
  import dualTemplate from "@/components/dualTemplate";
  import agenMClaves from "@/components/agenMClaves";

  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraMto, dualTemplate, agenMClaves },
    props: {},

    data() {
      return {
        stIni: {
          api: "agenM",
          name:"agenM",
          titulo:"Mantenimiento Agencias Afectas",  
          recordAccess:"local",
          mView:'',
          pView:[]
        }, 

        itemsClaves: [],
        selected:0,
        accionClave:0       
      };
    },

   
    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");       
        
      },


      verFin() {        
        this.itemsClaves= this.recordRead[1];
      },
      

      //
      addClaves() {        
        this.accionClave= 1;       
        this.componenteDinamico= 'agenMClaves';
      },


      //
      delClaves() {        
        this.accionClave= 2;       
        this.componenteDinamico= 'agenMClaves';
      },

     
      // actualizo list de la zona recibida
      updateClaves(records) {
        console.log('updateClaves: ', records);
        this.itemsClaves= records;
      },

    },


    watch: {
      /* selected() {
        if (this.selected== undefined) return;
        console.log('Sel: ', this.selected, this.itemsClaves[this.selected]);
      } */
    }
  }
</script>



<style scoped>
  .list_claves .v-list-item {
    min-height:25px !important;
  }

  .list_claves .v-list-item__content {
    padding: 0px 0px !important;
  }
</style>